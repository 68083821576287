import React, { useEffect } from "react";
import { Link } from "gatsby-plugin-intl";

import AppLayout from "../components/shared/AppLayout";

const PageIndex = () => {
    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <AppLayout hasHeader={false} hasHeaderLogo={false} hasFooter={false}>
            <div className="app-page page-landing">
                <div className="landing">
                    <div className="landing__box-wrapper">
                        <div className="landing__box landing__box--visual"></div>

                        <div className="landing__box landing__box--content">
                            <h1 className="landing__headline text-bold">隨時隨地提出服務要求</h1>

                            <h2 className="landing__lead">請即登入或登記使用客戶網上平台</h2>
                            
                            <Link className="landing__cta btn btn-lg" to={process.env.CUSTOMER_PORTAL_URL}>
                                客戶網上平台
                            </Link>

                            <h2 className="landing__lead">需要協助？與我們聯繫</h2>
                            
                            <ul className="landing__list">
                                <li className="landing__list-item">
                                    <span className="landing__list-icon landing__list-icon--phone"></span>
                                    <span className="landing__list-content">08 0901 0161</span>
                                </li>
                                <li className="landing__list-item">
                                    <span className="landing__list-icon landing__list-icon--email"></span>
                                    <span className="landing__list-content">cs.tw@care-plus.com.tw</span>
                                </li>
                                <li className="landing__list-item">
                                    <span className="landing__list-icon landing__list-icon--time"></span>
                                    <span className="landing__list-content">
                                        上午9點至晚上6點
                                        <br />
                                        週一至週五
                                        <br />
                                        (包含假日，農曆新年假期除外)
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <footer className="landing__footer">
                        <div className="landing__footer-container">
                            <span className="landing__footer-copyright">&copy;Samsung All Rights Reserved</span>
                            <span className="landing__footer-policy">
                                什麼是{" "}
                                <a href="https://www.samsung.com/tw/offer/samsung-care-plus/" target="_blank" rel="noreferrer">
                                    Samsung Care+?
                                </a>
                                {" "} | {" "}
                                <a href="https://www.samsung.com/tw/info/privacy/" target="_blank" rel="noreferrer">
                                    隱私權政策
                                </a>
                            </span>
                        </div>
                    </footer>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageIndex;
